import {Injectable} from '@angular/core'

// eslint-disable-next-line no-shadow
export enum LoanType {
  NONE = -1,
  HOUSING = 0,
  BLANCO,
  CARD_CREDIT
}

// eslint-disable-next-line no-shadow
export enum OccupationType {
  PERMANENT = 0, // Quite some business logic depends on 0 and 1 so do not move these to around!
  RETIRED = 1,
  TIME_LIMITED,
  HOURLY,
  OWN_COMPANY,
  STUDENT,
  UNEMPLOYED
}

// eslint-disable-next-line no-shadow
export enum PropertyType {
  NONE = -1,
  VILLA = 0,
  BOSTADSRATT = 1,
  HYRESRATT = 2,
  FRITIDSHUS = 3
}

// eslint-disable-next-line no-shadow
export enum UcDecision {
  APPROVED = 'J',
  DECLINED = 'N'
}

export const APPLICATION_NAME = 'blanco-application'

/**
 * A user as used in the admin parts.
 */
export interface IUser {
  /**
   * Basically personnummer. Should at least be masked
   */
  id: string

  /**
   * First and last name like "Jonas Jonasson"
   */
  name: string

  /**
   * An array of strings like ["admin", "developer"]
   */
  roles: string[]

  /**
   * The infamous S-ID
   */
  sId: string
}

/**
 * What is needed for mocking UC.
 */
export interface UCInfo {

  /**
   * The applicant full name like 'Claus 'Daniel' Lennert Bergdahl
   */
  applicantName: string

  /**
   * Länkod e.g. "01"
   */
  countyCode: string

  /**
   * Kommunkod e.g. "18"
   */
  municipalityCode: string

  /**
   * Församlingskod, not really used at the moment. E.g. "01"
   */
  parishCode?: string

  /**
   * Yearly income and array of strings with year income before tax
   * e.g. ["123000", "234000"] the first is last year, next the year before that.
   */
  income: Array<number>

  /**
   * Decision is "J" or "N", where J is approved and N is not.
   */
  decision: UcDecision

  /**
   * Reasons is an array of reasons as strings
   */
  reasons: Array<string>

  /**
   * Status is normally "complete" but can change in the future
   */
  status: string

  /**
   * The UC risk assessment
   */
  ucRisk: number
}

/**
 * Applicant data
 */
export interface Applicant {
  /**
   * Income before tax in SEK, is initially a string like "25 000" then converted
   * to a number 25000
   */
  income: number

  /**
   * The personnummer in free form
   */
  personNummer: string

  /**
   * Occupation 0 is employed, 1 retired
   */
  occupation: OccupationType

  /**
   * A convenience field for entering human readable occupation
   */
  occupationName?: string

  /**
   * Living expense, normally 9500 for the first and 7500 for second
   */
  livingExpense?: number

  /**
   * name
   */
  name: string

  /**
   * phone
   */
  phone: string

  /**
   * email
   */
  email: string
  /**
   * Display name is a calculated name with that is pretty to display.
   */
  displayName?: string

  tax: IApplicantTax

  taxReduction: IApplicantTaxReturn

  /**
   * Extra info
   */
  employer: string
  akassa: boolean
  spouse: string[]
}

export interface IApplicantTax {

  /**
   * The amount of tax to pay SEK/Mon
   */
  tax?: number

  /**
   * The column
   */
  taxColumn?: number | string

  /**
   * The pValue in percent that you pay in your kommun
   */
  taxValue: number | string

  /**
   * If you pay a percentage this pValue is set.
   */
  taxPercent?: number | string
}

export interface IApplicantTaxReturn {

  /**
   * How much is payed in interest in SEK per annum.
   */
  interest: number

  /**
   * The total in SEK you get back in interest per annum.
   */
  interestTaxReturn: number

  /**
   * For returns over 100K? you only get 21% back. The amount in SEK
   */
  lowReturn: number
}

/**
 * The loan offer as presented to customer.
 */
export interface ILoanOffer {
  /**
   * The amount applied for
   */
  amount: number

  /**
   * The offered interest
   */
  interest: number

  /**
   * The effective interest comes back as a string e.g. 5.11
   */
  effectiveInterest: string

  /**
   *
   */
  monthlyCost: number

  /**
   * Simple true false
   */
  granted: boolean

  /**
   * How many years to pay for this
   */
  durationYears: number

  /**
   * Effectively years * 12
   */
  durationMonths: number

  /**
   * The total cost of interest (TODO: Should include start fee?)
   */
  totalCost: number

  /**
   * A "reason" "code" from the backend, room for improvement
   */
  reason?: string
}

/**
 * Administrative data to add to the application.
 */
export interface ILoanPromiseAdmin {
  /**
   * Who is assigned to this. Should be a name "Daniel Bergdahl"
   */
  assignee?: string
}

/**
 * This is the complete loan promise
 */
export interface BlancoLoanApplication {
  /**
   * The current version, update this to reset old versions.
   */
  version: number

  /**
   * The uniqe id set by the database
   */
  readonly itemId?: string

  /**
   * The main applicant personnummer
   */
  applicantId: string
  /** The applicants */
  applicants: Applicant[]

  /**
   * Helper to know if we have co-applicant clicked.
   */
  coApplicant: boolean

  /**
   * Number of childrens below 18
   */
  childrenCount: number

  /**
   * The loan amount
   */
  loanAmount: number | string

  /**
   * The duration of the loan
   */
  loanDuration: number | string

  /**
   * Purpose of the loan
   */
  purposesOfLoan: LoanPurpose[] | string[]

  /**
   * A timestamp to detect if we have an old application stored
   * in local storage
   */
  timeStamp?: number

  /**
   * Indicates if terms and conditions are checked or not.
   */
  terms?: boolean

  /**
   * Indicates if terms and conditions are checked or not for manual application.
   */
  termsManual?: boolean

  /**
   * Other info, a free text field for user to enter some information
   */
  otherInfo?: string

  /**
   * Admin data if present
   */
  adminData?: ILoanPromiseAdmin

  /**
   *
   */
  primaryLiving: ILiving

  /*

   */
  additionalLiving?: ILiving

  studyLoan: number

  additionalLoans: boolean

  loans?: IApplicationLoanData

  /**
   * The offer given to customer
   */
  offer?: ILoanOffer

  /**
   * The result from UC, hopefully in the same
   * order as the applicants.
   */
  ucResult?: UCInfo[]

  /**
   * Status codes as reported by the backend.
   */
  codes?: IBlancoResultCode[]

  /**
   * The loan applied for
   */
  loan: IBlancoLoan

  houseHold?: IHouseHold

  living: ILiving[]

  kalpResult: IKalpResult

  blancoStatus?: number

  blancoState?: string
}

export interface IKalpResult {
  loans: IKalpLoan[]
  houseHold: IKalpHouseHold
  applicants: IKalpApplicant[]
  kalp: number
  monthlyCarCost?: number
  calculation: IKalpCalc[]
}

export interface IKalpCalc {
  text: string
  values: number[]
  sum: number
  type: string
}

export interface IKalpApplicant {
  name: string
  tax: number
  income: number
  interestTaxReturnMonth: number
  occupation: number
  livingExpense: number
  taxValue: number // This is actually tabell
  taxColumn: number // The selected column
}

export interface IKalpHouseHold {
  children: IKalpChildren
  debtQuota: IKalpDebtQuota
  additionalRepayment: number
  studyLoan: number
  cars?: IKalpCar[]
}

export interface IKalpCar {
  carType: string
  id: string
  monthlyCost: number
  type: string
}

export interface IKalpChildren {
  childrenCount: number
  childrenCost: number
  childrenBenefit: number
  childrenBenefitExtra: number
  childrenNetCost: number
}

export interface IKalpDebtQuota {
  mortgage: number
  quota: number
  totalIncome: number
  childrenBenefit: number
  income: number
}

export interface IKalpLoan {
  amount: number
  mortgageCostPerMonth: number
  interestPerMonth: number
  interestPercent: number
  interestPerYear: number
  repaymentPerMonth: number
  repaymentPercent: number
  solve: boolean
  type: string
}

export interface IHouseHold {
  debtQuota: number

  additionalRepayment: number

  children: any // really this, but dont work... {age: number; monthlyCost: number}[]

  childrenCost: number

  childrenBenefit: number

  childrenBenefitExtra: number

  childrenNet: number
}

export interface IApplicationLoanData {
  studyLoan: number
  monthlyChildcareCost: number
  additionalLoans: boolean
  loans: ILoanInfo[]
}

export interface ILoanInfo {
  amount: number
  type: number


}

export interface IBlancoLoan {
  amount: number
  duration: number
  purposes: string[]
}

export interface IBlancoResultCode {
  /**
   * Unix epoch ms.
   */
  timeStamp: number

  name: string

  pass: boolean

  values: any[]

  /**
   * Used in stepper function, 'ok' or 'failed' based
   * on pass
   */
  state?: string

  /**
   * The title to show in the admin UI
   */
  title?: string
}

export interface ILiving {

  type: PropertyType

  rent?: number | string

  fee?: number | string

  loan?: number | string

  operationCost?: number

  propertyFee?: number

}

/**
 * Item to use in lists of property types.
 */
export interface PropertyTypeListItem {
  name: string
  type: PropertyType
}

/**
 * Different types of loan, Blanco, Credit etc.
 */
export interface LoanTypeListItem {
  /**
   * Like Blanco etc.
   */
  name: string

  /**
   * Technical type - needs to be in sync with backend.
   */
  type: LoanType
}

export interface OccupationTypeListItem {
  /**
   * Like Tillsvidare, Arbeitslos etc.
   */
  name: string

  /**
   * Enum 0, 1, ... 6
   */
  value: OccupationType
}

export interface LoanPurpose {
  purpose: string
  selected: boolean
}

@Injectable()
export class GenericTypesService {
  /**
   * Types of additional loans
   */
  public static LoanTypes: Array<LoanTypeListItem> = [
    {
      name: 'Bostad',
      type: LoanType.HOUSING
    },
    {
      name: 'Privatlån',
      type: LoanType.BLANCO
    },
    {
      name: 'Kortkredit',
      type: LoanType.CARD_CREDIT
    }
  ]

  public static PropertyTypes: Array<PropertyTypeListItem> = [
    {
      name: 'Villa',
      type: PropertyType.VILLA
    },
    {
      name: 'Bostadsrätt',
      type: PropertyType.BOSTADSRATT
    },
    {
      name: 'Hyresrätt',
      type: PropertyType.HYRESRATT
    },
    {
      name: 'Fritidshus',
      type: PropertyType.FRITIDSHUS
    }
  ]

  /**
   * A list to use for displaying property types
   */
  public static OccupationTypes: OccupationTypeListItem[] = [
    {
      name: 'Tillsvidare',
      value: OccupationType.PERMANENT
    },
    {
      name: 'Visstid',
      value: OccupationType.TIME_LIMITED
    },
    {
      name: 'Timanställd',
      value: OccupationType.HOURLY
    },
    {
      name: 'Egen företagare',
      value: OccupationType.OWN_COMPANY
    },
    {
      name: 'Student',
      value: OccupationType.STUDENT
    },
    {
      name: 'Pensionär',
      value: OccupationType.RETIRED
    },
    {
      name: 'Arbetssökande',
      value: OccupationType.UNEMPLOYED
    }
  ]
}

