import {StepperSelectionEvent} from '@angular/cdk/stepper'
import {Directive, HostListener} from '@angular/core'
import {MatStepper} from '@angular/material/stepper'

@Directive({
  selector: '[spbStepperScroll]'
})
export class StepperScrollDirective {

  constructor(private stepper: MatStepper) {
  }

  @HostListener('selectionChange', ['$event'])
  selectionChanged(selection: StepperSelectionEvent) {
    // eslint-disable-next-line no-underscore-dangle
    const stepId = this.stepper._getStepLabelId(selection.selectedIndex - 1)
    this.scrollElement(stepId)
  }

  private scrollElement(elemId: string): void {
    const stepElement: HTMLElement | null = document.getElementById(elemId)
    if (stepElement) {
      setTimeout(() => {
        stepElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'center'
        })
      }, 100)
    }
  }
}
