<div class="holder">
  <div class="breadcrumbs">
    <span tabindex="-1" class="link"
          (keyup.enter)="navigate(-1)" (click)="navigate(-1)">
      Sparbanken Syd<span> |  </span>
    </span>

    @for (link of elements; track $index) {
      @if (!$first) {
        <span class="last"> /  </span>
      }

      @if (!$last) {
        <span tabindex={idx} class="link"
              (keyup.enter)="navigate($index)" (click)="navigate($index)">
          {{ link }}
        </span>
      } @else {
        <span class="last">{{ link }}</span>
      }
    }
  </div>
</div>
