import {Component} from '@angular/core'

@Component({
  selector: 'spb-wait-dialog',
  template: `
    <div matDialogTitle>Förbereder</div>
    <div matDialogContent>Vi förbereder BankID, ett ögonblick...</div>`
})
export class WaitDialogComponent {

}
