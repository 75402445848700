import {Directive} from '@angular/core'
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn
} from '@angular/forms'


export const checkboxValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const check = Object.keys(control.value).find(key => control.value[key] === true)
  if (!check) {
    return {
      error: {
        message: 'Du måste ange ett syfte, hitta på ett, det spelar inte så stor roll.'
      }
    }
  }
  return null
}


@Directive({
  selector: '[spbCheckboxValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: CheckboxValidator,
    multi: true
  }]
})
export class CheckboxValidator implements Validator {

  validate(control: AbstractControl): ValidationErrors | null {
    return checkboxValidator(control)
  }
}
