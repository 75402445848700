<div class="public-section">
  <div class="public-section-inner-holder">
    <a class="public-logo-holder"
       target="_blank"
       href="https://internetbank.sparbankensyd.se/">
      <em class="material-icons">lock_outline</em>
      <span>Internetbanken</span>
    </a>
  </div>
</div>

<div class="main-section">
  <div class="main-section-inner-holder">
    <spb-logo></spb-logo>
  </div>
</div>